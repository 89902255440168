import styled from 'styled-components';

export const HeaderUnderConstructionBannerStyles = styled.div`
  background-color: rgb(176 11 11);
  width: 100%;
  color: rgb(180, 205, 221);
  height: 25px;
  line-height: 22px;
`;

export const SiteHeaderBackground = styled.div`
  background-color: rgb(0 125 110);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  height: calc(100vh - 25px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  font-size: calc(10px + 2vmin);
  color: rgb(180, 205, 221);
`;

export const HeaderOuter = styled.div`
    position: relative;
    padding: 0 5vw;
`;

export const HeaderInner = styled.div`
    margin: 0 auto;
  max-width: 1040px;
  width: 100%;
`;

export const HeaderSiteNavBar = styled.div`
  display: none;
  /* display: flex; */
  overflow-y: hidden;
  position: relative;
  justify-content: space-between;
  align-items: flex-start;
  height: 64px;
  font-size: 1.3rem;
`;

export const HeaderInfoContainerStyles = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

    
    @media (max-width: 576px) {
        display: flex;
        flex-direction: column;
    }
`;

export const HeaderInfo = styled.div`
display: flex;
flex-direction: column;
text-align: left;
font-family: 'Roboto', sans-serif;
font-size: 2em;
`;

export const HeaderInfoSection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    &.description {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        
        @media (max-width: 576px) {
        align-items: center;
        margin-bottom: 15px;

        }
    }
`;

export const HeaderTextContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const HeaderSimpleText = styled.p`
    font-size: 0.45em;
    font-weight: 100;
    padding-right: 50px;
`;

export const HeaderFancyName = styled.span`
    font-family: 'Gemunu Libre', sans-serif;
    font-weight: 400;
    font-size: 3em;
    margin-left: 5px;
    margin-bottom: -15px;
`;

export const HeaderSocialsStyles = styled.div`  
  ul {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
        
    &> li:not(:first-child) {
      margin-left: 15px;
    }
    
    a {
        display: block;
        padding: 10px;
        border-radius: 50%;
        color: #233742;
        background-color: #b4cddd;
        font-size: 0;
        transition: background-color ease-out 0.1s;
    }
  }
  
  .common-social-icon {
    width: 18px;
    height: 18px;
  }
`;

export const HeaderRoundImage = styled.img`
    border-radius: 100%;
    max-width: 275px;
`;