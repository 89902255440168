import React from 'react';
import { 
  SiteHeaderBackground,
  HeaderOuter,
  HeaderInner,
  HeaderSiteNavBar,
} from './header.styles';
import HeaderUnderConstructionBanner from './HeaderUnderConstructionBanner';
import HeaderInfoContainer from './HeaderInfoContainer';

const Header = () => {
    
  return (
    <header>
      <HeaderUnderConstructionBanner/>
      <SiteHeaderBackground>
        <HeaderOuter>
          <HeaderInner>
            <HeaderSiteNavBar/>
            <HeaderInfoContainer/>
          </HeaderInner>
        </HeaderOuter>
      </SiteHeaderBackground>
    </header>
  );
}

export default Header;