import React from 'react';
import {
  HeaderInfoContainerStyles,
  HeaderInfo,
  HeaderInfoSection,
  HeaderTextContainer,
  HeaderSimpleText,
  HeaderFancyName,
  HeaderRoundImage
} from './header.styles';
import HeaderSocials from './HeaderSocials';

const HeaderInfoContainer = () => (
  <HeaderInfoContainerStyles>
    <HeaderInfo>
      <HeaderInfoSection>
        <HeaderTextContainer>
          <span className="spn-header-text">Hi,</span>
          <span className="spn-header-text">I am </span>
        </HeaderTextContainer>
        <HeaderTextContainer>
          <HeaderFancyName>Stratos.</HeaderFancyName>
        </HeaderTextContainer>
      </HeaderInfoSection>
      <HeaderInfoSection>
        <span className="spn-header-text">I build stuff!</span>
      </HeaderInfoSection>
      <HeaderInfoSection className="description">
        <HeaderSimpleText>
            I am a <b>software engineer</b> in <b>Munich</b>. You&apos;ll probably find me working on some <b>front-end</b>&nbsp;
            trying to center a div. This is my corner of the internet where I will share information about my self and whatever
            side-projects I will be working on.
        </HeaderSimpleText>
        <HeaderSocials/>
      </HeaderInfoSection>
    </HeaderInfo>
    <HeaderInfo className="site-header-info">
      <div className="site-header-image">
        <HeaderRoundImage className="round-image" src="/efstratios-chatzidakis.png" alt="" />
      </div>
    </HeaderInfo>
  </HeaderInfoContainerStyles>
);

export default HeaderInfoContainer;