import React from 'react';
import { AppStyles } from './App.styles';
import Header from './components/header/Header.component';

function App() {
  return (
    <AppStyles className="App">
      <Header />
    </AppStyles>
  );
}

export default App;
