import React from 'react';
import { HeaderSocialsStyles } from './header.styles';

import { FaLinkedinIn } from 'react-icons/fa';
import { GrMailOption } from 'react-icons/gr';
import { GrDocumentUser } from 'react-icons/gr';

const HeaderSocials = () => (
  <HeaderSocialsStyles>
    <ul className="header-socials-list">
      <li>
        <a href="https://echatzidakis.notion.site/echatzidakis/Efstratios-Chatzidakis-10ce73ce67c648c4929f2b898a586bfb" target="_blank" rel="noreferrer" title="Resume">
          <GrDocumentUser className="common-social-icon" />
        </a>
      </li>
      <li>
        <a href="mailto:stratos.chatzis@hotmail.com" title="Email">
          <GrMailOption className="common-social-icon" />
        </a>
      </li>
      <li>
        <a href="https://www.linkedin.com/in/stratos-chatzidakis-7a9b8016a/" target="_blank" rel="noreferrer" title="LinkedIn">
          <FaLinkedinIn className="common-social-icon" />
        </a>
      </li>
    </ul>
  </HeaderSocialsStyles>
);

export default HeaderSocials;